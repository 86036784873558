// images
import TopBackground from '../images/foursource-portugal.jpg';
import AnaCorreiaImage from '../images/team/ana-correia.jpg';
import JoaoMoreiraImage from '../images/team/joao-moreira.jpg';
import SusanaCunhaImage from '../images/team/susana-cunha.jpg';

export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t('branches.portugal.topTitle'),
        colorClass: 'colorLight',
      },
      bottomDiagonal: true,
      opacity: true,
      textBottom: true,
      image: {
        src: TopBackground,
        alt: t('branches.portugal.imgAlt'),
        smallHeight: true,
      },
    },
    ourMission: {
      title: {
        text: t('branches.portugal.ourMission'),
        colorClass: 'colorDark',
        extraClass: 'font40',
      },
      text: {
        text: t('branches.portugal.missingTransparency'),
        colorClass: 'colorDark mb-40',
        textType: 'paragraph',
      },
      separator: true,
    },
    aboutTeamPortugal: {
      title: {
        text: t('branches.portugal.aboutTeamPortugal.title'),
        colorClass: 'colorDark',
        extraClass: 'font40',
      },
      text: {
        text: t('branches.portugal.aboutTeamPortugal.description'),
        colorClass: 'colorDark',
        textType: 'paragraph',
      },
      separator: true,
    },
    ourTeam: {
      title: {
        text: t('branches.portugal.theTeam'),
        colorClass: 'colorDark',
        extraClass: 'font40',
      },
      separator: true,
    },
    whereWeAre: {
      title: {
        text: t('branches.portugal.whereWeAre'),
        colorClass: 'colorDark',
        extraClass: 'font40',
      },
      separator: true,
    },
    teamMembers: [
      {
        text: {
          name: t('branches.portugal.anaCorreia.name'),
          phone: t('branches.portugal.anaCorreia.phone'),
          email: t('branches.portugal.anaCorreia.email'),
          position: t('branches.portugal.anaCorreia.position'),
        },
        img: {
          src: AnaCorreiaImage,
          alt: t('branches.portugal.anaCorreia.alt'),
          extraClass: 'w-100',
        },
        extraClass: 'col-md-6 col-lg-4',
        alt: t('branches.portugal.anaCorreia.alt'),
        separator: true,
      },
      {
        text: {
          name: t('branches.portugal.joaoMoreira.name'),
          phone: t('branches.portugal.joaoMoreira.phone'),
          email: t('branches.portugal.joaoMoreira.email'),
          position: t('branches.portugal.joaoMoreira.position'),
        },
        img: {
          src: JoaoMoreiraImage,
          alt: t('branches.portugal.joaoMoreira.alt'),
          extraClass: 'w-100',
        },
        alt: t('branches.portugal.joaoMoreira.alt'),
        separator: true,
        extraClass: 'col-md-6 col-lg-4',
      },
      {
        text: {
          name: t('branches.portugal.susanaCunha.name'),
          phone: t('branches.portugal.susanaCunha.phone'),
          email: t('branches.portugal.susanaCunha.email'),
          position: t('branches.portugal.susanaCunha.position'),
        },
        img: {
          src: SusanaCunhaImage,
          alt: t('branches.portugal.susanaCunha.alt'),
          extraClass: 'w-100',
        },
        extraClass: 'col-md-6 col-lg-4',
        alt: t('branches.portugal.susanaCunha.alt'),
        separator: true,
      },
    ],
  };
};
