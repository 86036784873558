/**
 * Foursource page
 * https://foursource.com/branches/portugal
 */

import * as React from 'react';
import { withTrans } from '../../i18n/withTrans';

import { pageTexts } from '../../content/portugal';
import { contactTexts } from '../../content/pages/contact';
import { valuesTexts } from '../../content/pages/values';

// components
import Layout from '../../components/layout';
import TextImgBackgroundFull from '../../components/textImgBackgroundFull';
import TitleSeparator from '../../components/titleSeparator';
import PhotoCardHover from '../../components/photoCardHover';
import Contact from '../../components/_page/_contact';
import Values from '../../components/_page/_values';

class branchesPortugalPage extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.text = pageTexts(this.t);
    this.contactTexts = contactTexts(this.t);
    this.valuesTexts = valuesTexts(this.t);
  }

  render() {
    return (
      <>
        <Layout pageName='branches.portugal' whiteMenu={false}>
          <TextImgBackgroundFull info={this.text.topInfo}></TextImgBackgroundFull>
          <div className='paddingSidesBigger pt-30 pb-50 text-center'>
            <TitleSeparator info={this.text.ourMission}></TitleSeparator>
            <TitleSeparator info={this.text.aboutTeamPortugal}></TitleSeparator>
          </div>
          <div className='paddingSidesBig backgroundGrey topBottomDiagonal pt-30 text-center justify-content-center'>
            <div className='container pt-100 pb-100'>
              <TitleSeparator info={this.text.ourTeam}></TitleSeparator>
              <div className='row'>
                {this.text.teamMembers.map((member, i) => (
                  <PhotoCardHover key={i} info={member}></PhotoCardHover>
                ))}
              </div>
            </div>
          </div>
          <div className='paddingSidesBigger pt-30 pb-30 text-center'>
            <TitleSeparator info={this.text.whereWeAre}></TitleSeparator>
            <p className='font18 fontWeightBold colorDark mb-10'>{this.t('branches.portugal.companyName')}</p>
            <p className='font17 colorDark mb-10'>{this.t('branches.portugal.street')}</p>
            <p className='font17 colorDark mb-10'>{this.t('branches.portugal.postalCode')}</p>
            <p className='font17 colorDark mb-10'>{this.t('branches.portugal.portugal')}</p>
            <p className='font17 colorDark mb-10'>{this.t('branches.portugal.vat')}</p>
          </div>
          <div className='backgroundGrey topBottomDiagonal pt-50 pb-50'>
            <Values text={this.valuesTexts}></Values>
          </div>
          <Contact text={this.contactTexts.ourContact}></Contact>
        </Layout>
      </>
    );
  }
}

export default withTrans(branchesPortugalPage);
